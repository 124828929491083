import Splide from '@splidejs/splide';
//DOM loaded
document.addEventListener("DOMContentLoaded", () => {

    //Carousel init
    let carousel = document.querySelectorAll('.pdw-carousel');
    let carouselArray = new Object();

    carousel.forEach((item, index) => {
        let dataPerviewDefault = parseInt(item.dataset.perview);
        let dataPerview = {
            9999: parseInt(item.dataset.perviewxxl),
            1399: parseInt(item.dataset.perviewxl),
            1199: parseInt(item.dataset.perviewlg),
            991: parseInt(item.dataset.perviewmd),
            767: parseInt(item.dataset.perviewsm),
            575: parseInt(item.dataset.perviewxs)
        }
        let breakPoints = {};
        Object.keys(dataPerview).forEach(key => {
            if (Number.isInteger(dataPerview[key])) {
                breakPoints[key] = {
                    perPage: dataPerview[key]
                };
            } else {
                if (key === '767') {
                    breakPoints[key] = {
                        perPage: 1
                    };
                }
                if (key === '991') {
                    breakPoints[key] = {
                        perPage: (dataPerviewDefault > 1) ? dataPerviewDefault - 1 : 1
                    };
                }
            }
        });
        let dataType = 'loop';
        if ("type" in item.dataset) {
            dataType = item.dataset.type;
        }
        let dataGap = parseInt(item.dataset.gap);
        let dataPeekBefore = 0;
        if ("peekbefore" in item.dataset) {
            dataPeekBefore = parseInt(item.dataset.peekbefore);
        }
        let dataPeekAfter = 0;
        if ("peekafter" in item.dataset) {
            dataPeekAfter = parseInt(item.dataset.peekafter);
        }
        let conf = {
            type: dataType,
            arrows: true,
            pagination: true,
            rewind: false,
            perPage: dataPerviewDefault,
            perMove: 1,
            gap: dataGap + 'px',
            autoplay: false,
            breakpoints: breakPoints,
            padding: {
                left: dataPeekBefore,
                right: dataPeekAfter
            }
        }

        carouselArray['carousel' + index] = new Splide(item, conf);

        let carouselCounter = item.querySelectorAll('.pdw-carousel-counter > .splide');
        if (carouselCounter.length > 0) {
            let confCounter = {
                perPage: 1,
                type: 'fade',
            rewind: true,
                isNavigation: true,
                gap: 0,
                focus: 'center',
                pagination: false,
                arrows: false
        }
            carouselCounter.forEach((counter, indexCounter) => {
                carouselArray['carouselCounter' + index + 'i' + indexCounter] = new Splide(counter, confCounter);
                carouselArray['carousel' + index].sync(carouselArray['carouselCounter' + index + 'i' + indexCounter]);
            });
        }

        let carouselThumbnails = item.querySelectorAll('.pdw-carousel-thumbnails');
        if (carouselThumbnails.length > 0) {
            let confThumbnails = {
                perPage: 1,
                perMove: 1,
                type: 'loop',
                rewind: true,
                isNavigation: true,
                gap: 10,
                pagination: false,
                arrows: true,
                direction: 'ttb',
                fixedWidth : 100,
                fixedHeight : 100,
                height: '100vh'
            }
            carouselThumbnails.forEach((thumbnails, indexThumbnails) => {
                carouselArray['carouselThumbnails' + index + 'i' + indexThumbnails] = new Splide(thumbnails, confThumbnails);
                carouselArray['carousel' + index].sync(carouselArray['carouselThumbnails' + index + 'i' + indexThumbnails]);
            });
        }
    });

    Object.values(carouselArray).forEach(carouselItem => {
        carouselItem.mount();
    });
});