//Drilldown menu
window.addEventListener("DOMContentLoaded", (event) => {
    document.querySelectorAll('ul.mobile-drilldown-menu li.menu-item-has-children > a').forEach(element => {
        //Create previous button
        let previous = document.createElement('span');
        element.nextElementSibling.prepend(previous);
        previous.classList.add("pdw-submenu-close");
        previous.innerHTML = element.innerHTML;
        previous.addEventListener("click", (event) => {
            event.preventDefault();
            event.target.parentElement.parentElement.classList.remove("open");
        });
        //Create open button
        let span = document.createElement('span');
        element.appendChild(span);
        span.classList.add("pdw-submenu-open");
        span.addEventListener("click", (event) => {
            event.preventDefault();
            event.target.parentElement.parentElement.classList.add("open");
            document.getElementById('pdw-mobile-menu').scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        });
    });
});