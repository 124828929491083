//Function to enlarge column
const enlargeColumn = (el, side) => {

    //WPBakery Page Builder Fix
    if (el.classList.contains('vc_column-inner')) {
        el = el.parentElement;
    }

    //Check data-enlarge-up
    if ('enlargeUp' in el.dataset) {
        if (document.body.clientWidth < el.dataset.enlargeUp) {
            side = 'both';
        }
    }

    //Remove style to have real values
    el.removeAttribute('style');

    //Calculate width and margin needed
    let elComputedStyle = window.getComputedStyle(el);
    let thiswidth = el.clientWidth;
    let thisml = parseInt(elComputedStyle.marginLeft);
    let thismr = parseInt(elComputedStyle.marginRight);
    if (thisml > 0) {
        thisml = 0;
    }
    if (thismr > 0) {
        thismr = 0;
    }
    let thism = -thismr - thisml;
    let width = document.querySelector('#content > .container').clientWidth;
    let fullwidth = document.querySelector('#content').clientWidth;
    let marginValue = String((width - fullwidth) / 2) + 'px';
    let elNewWidth = 'calc( ' + String((100 / (width / thiswidth))) + '% + ' + String(((fullwidth - width) / 2) + thism) + 'px )';

    //Set style
    if (side === 'left') {
        el.style.marginLeft = marginValue;
    } else if (side === 'right') {
        el.style.marginRight = marginValue;
    } else if (side === 'both') {
        el.style.marginLeft = marginValue;
        el.style.marginRight = marginValue;
        elNewWidth = fullwidth + 'px';
    }
    el.style.width = elNewWidth;
    el.style.maxWidth = 'unset';

}

//Function to run enlarge
const enlargeInit = () => {

    //Enlarge left
    let enlargeL = document.querySelectorAll('.enlarge-l');
    enlargeL.forEach(el => enlargeColumn(el, 'left'));

    //Enlarge right
    let enlargeR = document.querySelectorAll('.enlarge-r');
    enlargeR.forEach(el => enlargeColumn(el, 'right'));

}

//DOM loaded
document.addEventListener("DOMContentLoaded", () => {

    enlargeInit();

    //Window resize event
    window.onresize = (event) => {
        enlargeInit();
    };

});