//Textes
const hideTelText = {
    "fr-FR": "Voir le numéro",
    "en-US": "Show the number",
    "nl-NL": "Zie nummer",
    "es-ES": "Ver numero"
}
const hideMailText = {
    "fr-FR": "Voir l'email",
    "en-US": "Show the email",
    "nl-NL": "Zie email",
    "es-ES": "Ver email"
}

//Functions
function isHTML(str) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

//DOM loaded
document.addEventListener("DOMContentLoaded", () => {
    let pageLang = document.documentElement.lang;

    //Masque les numéros de téléphone
    document.querySelectorAll('a[href^="tel:"]').forEach((el) => {
        if (!isHTML(el.innerHTML) && el.innerHTML != '') {
            el.classList.add('pdw-tel-hidden');
            el.dataset.dataHref = el.href;
            el.dataset.dataTel = el.innerHTML;
            el.removeAttribute('href');
            el.innerHTML = hideTelText[pageLang];
            el.addEventListener('click', function telClick(e) {
                e.preventDefault();
                e.target.innerHTML = e.target.dataset.dataTel;
                e.target.href = e.target.dataset.dataHref;
                e.target.classList.remove('pdw-tel-hidden');
                e.target.classList.remove('pdw-tel-visible');
                e.target.removeEventListener('click', telClick);
            });
        }
    });

    //Masque les adresses email
    document.querySelectorAll('a[href^="mailto:"]').forEach((el) => {
        if (!isHTML(el.innerHTML) && el.innerHTML != '') {
            el.classList.add('pdw-mailto-hidden');
            el.dataset.dataHref = el.href;
            el.dataset.dataMailto = el.innerHTML;
            el.removeAttribute('href');
            el.innerHTML = hideMailText[pageLang];
            el.addEventListener('click', function mailtoClick(e) {
                e.preventDefault();
                e.target.innerHTML = e.target.dataset.dataMailto;
                e.target.href = e.target.dataset.dataHref;
                e.target.classList.remove('pdw-mailto-hidden');
                e.target.classList.remove('pdw-mailto-visible');
                e.target.removeEventListener('click', mailtoClick);
            });
        }
    });

});
